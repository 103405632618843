// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  fallbackTenant: 'cambridge',
  blockRegistrationLink: 'https://access-test.swiss-exams.ch/login/school',
  assistanceLink: 'https://support.swiss-exams.ch',
  assistanceLinkForSchoolsAndTeachersEn: 'https://swiss-exams.ch/contact',
  assistanceLinkForSchoolsAndTeachersDe: 'https://swiss-exams.ch/de/kontakt',
  sentryDsn: 'https://f7e7ada0ab294cad8bcf239ae135376f@o1087817.ingest.sentry.io/4504093980360704',
  // This value will be set via gitlab
  sentryVersion: '73437d6c62d1edcb1b21b2d19618a4e0dd0d25de',
  env: 'Testing',
  sentryTracesSampleRate: 0.75,
  enableSentry: true,
};
