import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { Configuration } from '../../generated/api';
import { AppRoutingModule } from './app-routing.module';
import { AppWildcardRoutingModule } from './app-wildcard-routing.module';
import { CandidateModule } from './candidate/candidate.module';
import { CompleteModule } from './complete/complete.module';
import { AppServerComponent } from './core/components/app-server.component';
import { AppComponent } from './core/components/app.component';
import { FooterComponent } from './core/components/footer.component';
import { ConfigInitializedGuard } from './core/config-initialized.guard';
import { API_CONFIGURATION } from './core/initializer/api-configuration';
import { InitializerService } from './core/initializer/initializer.service';
import { NavigationComponent } from './core/navigation/navigation.component';
import { OrUnloadTrackerApi, orUnloadTrackerApiFactory } from './core/or-unload-tracker.api';
import { TenantInterceptor } from './core/tenant/tenant.interceptor';
import { TenantService } from './core/tenant/tenant.service';
import { UniversalRelativeInterceptor } from './core/universal-relative.interceptor';
import { EmptyComponent } from './empty.component';
import { HealthComponent } from './health/health.component';
import { InitModule } from './init/init.module';
import { LegacyFilterGuard } from './legacy-filter.guard';
import { ListModule } from './list/list.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { PaymentModule } from './payment/payment.module';
import { SharedModule } from './shared/shared.module';
import { UNLOAD_TRACKER_API_TOKEN } from './shared/unload-tracker/unload-tracker.api';
import { StaticPagesModule } from './static-pages/static-pages.module';
import { SummaryModule } from './summary/summary.module';
import { StripTranslateHttpLoader } from './util/strip-translate-http-loader';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { NationalityValidator } from './util/form/validators/nationality-validator';

export function initializeApp(initializer: InitializerService) {
  return (): Promise<any> => initializer.initialize();
}
export function HttpLoaderFactory(transferState: TransferState, platformId, http: HttpClient) {
  return new StripTranslateHttpLoader(transferState, platformId, http, './assets/i18n/', '.json');
}

const SentryService = {
  provide: ErrorHandler,
  useValue: Sentry.createErrorHandler({
    showDialog: false,
  }),
};

const SentryTracerService = {
  provide: Sentry.TraceService,
  deps: [Router],
};

@NgModule({
  declarations: [
    AppComponent,
    AppServerComponent,
    NavigationComponent,
    FooterComponent,
    EmptyComponent,
    HealthComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TransferState, PLATFORM_ID, HttpClient],
      },
    }),
    ListModule,
    InitModule,
    CandidateModule,
    SummaryModule,
    PaymentModule,
    CompleteModule,
    StaticPagesModule,
    AppWildcardRoutingModule,
    PageNotFoundModule,
    NgCircleProgressModule.forRoot({
      radius: 40,
      outerStrokeColor: '#7397B7',
      showUnits: false,
      showInnerStroke: true,
      showSubtitle: false,
      space: -7,
      innerStrokeWidth: 6,
      innerStrokeColor: '#ccc',
      // Animations seem to make trouble if the radial progress is displayed
      // in the bottom sheet. It looks like the animation only plays half,
      // so the radial progress shows a lower value than it is.
      // Disabling animations fixes this issue.
      animateTitle: false,
      animateSubtitle: false,
      animation: false,
      renderOnClick: false,
    }),
  ],
  providers: [
    LegacyFilterGuard,
    TransferState,
    {
      provide: 'googleTagManagerId',
      useFactory: (tenantService: TenantService) =>
        tenantService.isProduction
          ? tenantService.tenant.googleTagId
          : tenantService.tenant.googleTagIdForTest,
      deps: [TenantService],
    },
    { provide: APP_ID, useValue: 'serverApp' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [InitializerService],
      multi: true,
    },
    InitializerService,
    ConfigInitializedGuard,
    { provide: Configuration, useValue: API_CONFIGURATION },
    TenantService,
    { provide: HTTP_INTERCEPTORS, useClass: UniversalRelativeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    OrUnloadTrackerApi,
    {
      provide: UNLOAD_TRACKER_API_TOKEN,
      useFactory: orUnloadTrackerApiFactory,
      deps: [OrUnloadTrackerApi],
    },
    SentryService,
    SentryTracerService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
