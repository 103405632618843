import { Injectable, makeStateKey } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TenantService } from 'src/app/core/tenant/tenant.service';
import { LocalStorageService } from '../providers/local-storage.service';
const LANG_KEY = makeStateKey<string>('lang');

@Injectable({
  providedIn: 'root',
})
export class TranslateLanguageService {
  private langSubject = new BehaviorSubject<string>(this.getLanguage());
  private readonly lang$ = this.langSubject.asObservable();
  // private readonly formalSubject = new BehaviorSubject<boolean>(false);
  // private readonly formal$ = this.formalSubject.asObservable();

  set lang(lang: string) {
    if (lang && this.langSubject.getValue() !== lang) {
      this.langSubject.next(lang);
    }
  }

  /*
  set formal(formal: boolean) {
    if (this.formalSubject.getValue() !== formal) {
      this.formalSubject.next(formal);
    }
  }
  */

  constructor(
    private translate: TranslateService,
    private transferState: TransferState,
    private tenantService: TenantService,
    private localStorage: LocalStorageService,
  ) {
    /*
    combineLatest([
      this.lang$,
      this.formal$,
    ])
    */
    const value = this.transferState.get(LANG_KEY, null);
    if (value === null) {
      const userPreferredLang = localStorage.getAs<string>('lang') || translate.getBrowserLang();
      const isSupportedLang = !!this.tenantService.tenant.languages.find(
        (lang) => lang === userPreferredLang,
      );
      const userLang = isSupportedLang ? userPreferredLang : translate.defaultLang;
      this.translate.use(userLang);
    } else {
      this.translate.use(value);
    }
    this.lang$.subscribe((lang) => {
      if (this.translate.currentLang !== lang) {
        this.translate.use(lang);
      }
    });
  }

  getLanguage() {
    const languageParam = this.transferState.get(LANG_KEY, null);
    if (languageParam === null) {
      const userPreferredLang =
        this.localStorage.getAs<string>('lang') || this.translate.getBrowserLang();
      const isSupportedLang = !!this.tenantService.tenant.languages.find(
        (lang) => lang === userPreferredLang,
      );
      const userLang = isSupportedLang ? userPreferredLang : this.translate.defaultLang;
      return userLang;
    } else {
      const isSupportedLang = !!this.tenantService.tenant.languages.find(
        (lang) => lang === languageParam,
      );
      return isSupportedLang ? languageParam : this.tenantService.tenant.languages[0];
    }
  }
}
