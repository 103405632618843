import { Component, HostBinding, Inject, makeStateKey, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { fader } from './route-animations';
import { TenantService } from '../tenant/tenant.service';
import { Tenant } from '../tenant/tenant';
import { TranslateService } from '@ngx-translate/core';
import { TranslateLanguageService } from '../../shared/translate/translate-language.service';
import { LocalStorageService } from '../../shared/providers/local-storage.service';
import { Title, TransferState } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { RegistrationProcessService } from '../../shared/providers/registration-process.service';
const LANG_KEY = makeStateKey<string>('lang');

@Component({
  selector: 'sx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fader],
})
export class AppComponent {
  @HostBinding('class')
  cssClass = 'root';

  tenant: Tenant;
  isServer = false;
  userLang: string;

  constructor(
    private translate: TranslateService,
    private translateLanguage: TranslateLanguageService,
    private router: Router,
    private tenantService: TenantService,
    private localStorage: LocalStorageService,
    private titleService: Title,
    private googleTagManagerService: GoogleTagManagerService,
    private process: RegistrationProcessService,
    private route: ActivatedRoute,
    private transferState: TransferState,
  ) {
    this.isServer = !tenantService.isBrowser;
    this.tenant = tenantService.tenant;
    const { isProduction } = tenantService;
    const { googleTagId, googleTagIdForTest } = this.tenant;

    if (tenantService.isBrowser && isProduction && googleTagId) {
      console.log('Google Tag Manager is enabled');

      this.googleTagManagerService.addGtmToDom();

      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
            referrer: document?.domain,
          };

          // The pushTag method from the library angular-google-tag-manager is not working properly
          // It injects a new GTMscript tag with every pushTag call. This is not the desired behavior.
          // The following code is using vanilla JS to push the tag to the dataLayer.
          // TODO: We should consider to remove the 3rd party library angular-google-tag-manager completely
          // this.googleTagManagerService.pushTag(gtmTag);

          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push(gtmTag);

          console.log('DataLayer', (window as any).dataLayer);
        }
      });
    } else if (tenantService.isBrowser && !isProduction && googleTagIdForTest) {
      console.log('Google Tag Manager for test is enabled');

      this.googleTagManagerService.addGtmToDom();

      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
            referrer: document?.domain,
          };

          // The pushTag method from the library angular-google-tag-manager is not working properly
          // It injects a new GTMscript tag with every pushTag call. This is not the desired behavior.
          // The following code is using vanilla JS to push the tag to the dataLayer.
          // TODO: We should consider to remove the 3rd party library angular-google-tag-manager completely
          // this.googleTagManagerService.pushTag(gtmTag);

          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push(gtmTag);

          console.log('DataLayer', (window as any).dataLayer);
        }
      });
    }
    const storedLang = this.transferState.get(LANG_KEY, null);
    if (storedLang !== null && !!this.tenant.languages.find((lang) => lang === storedLang)) {
      localStorage.put('lang', storedLang);
    }

    translate.addLangs(this.tenant.languages);
    translate.setDefaultLang(this.tenant.languages[0]);
    const userPreferredLang = localStorage.getAs<string>('lang') || translate.getBrowserLang();
    const isSupportedLang = !!this.tenant.languages.find((lang) => lang === userPreferredLang);
    this.userLang = isSupportedLang ? userPreferredLang : translate.defaultLang;
    if (storedLang === null) {
      this.translateLanguage.lang = this.userLang;
    }
    this.titleService.setTitle(this.tenant.title);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  onChangeLanguage(language: string) {
    this.userLang = language;
    this.translateLanguage.lang = this.userLang;
    this.localStorage.put('lang', this.userLang);
  }

  get examsLogoLink() {
    const { examsLogoLinkEn, examsLogoLinkDe, examsLogoLinkFr } = this.tenant;
    switch (this.userLang) {
      case 'en':
        return examsLogoLinkEn;
      case 'de':
        return examsLogoLinkDe;
      case 'fr':
        return examsLogoLinkFr;
      default:
        return examsLogoLinkEn;
    }
  }

  get sxLogoLink() {
    const baseUrl = 'https://swiss-exams.ch';

    switch (this.userLang) {
      case 'en':
        return `${baseUrl}/en`;
      case 'de':
        return `${baseUrl}/de`;
      case 'fr':
        return `${baseUrl}/fr`;
      default:
        return `${baseUrl}/en`;
    }
  }
}
